import React from "react";
import { Link, graphql } from "gatsby";
import SEO from "../components/seo";
import Layout from "../layouts";

/* 404 Page
============================================================================= */

const NotFoundPage = ({ data, location }) => {
    // Config

    // Render
    return (
        <Layout location={location}>
            <SEO title="404: Not Found" />
            <div
                className="relative flex flex-row items-center justify-center w-screen h-screen overflow-hidden"
                style={{ backgroundColor: "#C69AF4" }}
            >
                <div className="container relative z-10 flex flex-row items-center justify-start px-8 mx-auto md:px-4">
                    <div className="flex flex-col items-start justify-center">
                        <h1
                            className="mb-4 text-6xl uppercase cursor-pointer md:text-8xl"
                            style={{
                                transform:
                                    "scaleY(-1) scaleX(-1) translateY(-25px)",
                                fontFamily: "Kritik-Bold",
                                color: "#d3c846",
                            }}
                        >
                            404
                        </h1>
                        <h1
                            className="text-5xl uppercase cursor-pointer"
                            style={{
                                fontFamily: "Kritik-Bold",
                                color: "rgb(249, 245, 237)",
                            }}
                        >
                            UP. SIDE. DOWN.
                        </h1>
                        <h1
                            className="text-5xl uppercase cursor-pointer"
                            style={{
                                fontFamily: "Kritik-Bold",
                                color: "rgb(249, 245, 237)",
                            }}
                        >
                            BOY, YOU TURN ME.
                        </h1>
                        <h1
                            className="text-5xl uppercase cursor-pointer"
                            style={{
                                fontFamily: "Kritik-Bold",
                                color: "rgb(249, 245, 237)",
                            }}
                        >
                            IN. SIDE. OUT.
                        </h1>
                        <Link
                            to="/"
                            className={`form-button px-6 pt-2 pb-3 text-xl mt-8`}
                            style={{ color: "rgb(249, 245, 237)" }}
                        >
                            GO HOME
                        </Link>
                    </div>
                </div>
                <img
                    src="404curly.png"
                    className="absolute bottom-0 right-0 z-0 w-screen origin-bottom-right md:h-screen md:w-auto"
                    style={{
                        transformOrigin: "100% 50%",
                        transform:
                            "scale(1.6) translateY(-45px) translateX(16px)",
                    }}
                />
            </div>
        </Layout>
    );
};

/* Page Query
============================================================================= */

export default NotFoundPage;
